import { Link } from 'react-router-dom';
import ArrowRight from './arrow-right';
import Tag from './tag';
import AuthorAttribution from './author-attribution';
import AuthorAvatar from './author-avatar';
import { extractDescription, removeDescTags } from 'src/utils/format-blog';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { IBlogItem } from 'src/types/blog';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from '../../utils/string';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

export default function PostCard({ post }: { post: IBlogItem }) {
	const { getUrl } = useMultiLngRoutes();
	const { t, i18n } = useTranslation();

	const constants = useAppConstants();
	const translations = useConstantsTranslation(i18n.language);

	const categories = constants?.blog.CATEGORIES;

	return (
		<div>
			{post.cover[0] && (
				<Link to={getUrl('blog_details', { url: post.url })}>
					<img
						className="mb-5 h-[400px] w-full rounded-xl bg-no-repeat object-cover object-center transition-transform duration-200 ease-out hover:scale-[1.02]"
						src={getImageUrl(post.cover[0])}
						alt={post.title}
					/>
				</Link>
			)}
			<h2 className="pb-3 text-xl font-semibold tracking-tight text-zinc-800 ">
				<Link to={getUrl('blog_details', { url: post.url })}>{post.title}</Link>
			</h2>
			<div className="flex flex-col justify-between space-y-4 md:flex-row md:space-y-0">
				<div className="flex items-center space-x-2 text-zinc-500 md:space-y-0 ">
					<AuthorAvatar post={post} />
					<AuthorAttribution post={post} />
				</div>
				<div className="flex select-none justify-start space-x-2 md:hidden md:justify-end">
					{post.categories &&
						post.categories.map((category) => (
							<Tag key={category}>
								{categories && translations?.CATEGORIES[categories?.[Number(category)]]}
							</Tag>
						))}
				</div>
			</div>
			<div
				className="py-6 text-zinc-500 "
				dangerouslySetInnerHTML={{
					__html: extractDescription(post.content) ?? ''
				}}
			/>
			<div className="flex items-center justify-between font-medium text-green-600 ">
				<Link to={getUrl('blog_details', { url: post.url })}>
					<div className="flex items-center space-x-2">
						<span>{t('read_article')}</span>
						<ArrowRight className="h-4 w-4 text-inherit" />
					</div>
				</Link>
				<div className="hidden select-none justify-end space-x-2 md:flex ">
					{post.categories &&
						post.categories.map((category) => (
							<Tag key={category}>
								{categories && translations?.CATEGORIES[categories?.[Number(category)]]}
							</Tag>
						))}
				</div>
			</div>
		</div>
	);
}
