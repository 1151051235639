import { Link } from 'react-router-dom';
import { stringToFriendlyDate } from 'src/utils/date';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { IBlogItem } from 'src/types/blog';
import { useTranslation } from 'react-i18next';
import { getImageUrl } from '../../utils/string';
import Avatar from '../../assets/images/avatar.jpg';
import { format } from 'date-fns';

export default function PostCard({ post }: { post: IBlogItem }) {
	const { getUrl } = useMultiLngRoutes();

	const { t } = useTranslation();
	return (
		<div>
			{post.cover[0] && (
				<Link to={getUrl('blog_details', { url: post.url })}>
					<img
						className="mb-5 h-[240px] rounded-xl bg-no-repeat object-cover object-center transition-transform duration-200 ease-out hover:scale-[1.02]"
						src={getImageUrl(post.cover[0])}
						alt={post.title}
					/>
				</Link>
			)}
			<h2 className="pb-3 text-xl font-semibold text-zinc-800">
				<Link to={getUrl('blog_details', { id: post.id })}>{post.title}</Link>
			</h2>
			<div className="flex items-center space-x-2 text-zinc-500">
				<img className="h-8 w-8 rounded-full" src={Avatar} alt={post.title} />
				<div>
					<span className="flex items-center gap-2">
						{t('by')}{' '}
						<p className="font-semibold text-green-600">
							{post.user.firstName} {post.user.lastName}
						</p>{' '}
						{t('on')} {format(post.publishedAt || post.createdAt, 'dd.MM.yyyy')}
					</span>
				</div>
			</div>
		</div>
	);
}
