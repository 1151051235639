import cx from 'classnames';
import { useTranslation, Trans } from 'react-i18next';

import GreenlineImage from 'src/assets/images/green_line_075.svg';

function SimpcarLogo() {
	return (
		<span className="text-primary relative">
			simpcar
			<img
				className="absolute w-full left-0 -bottom-[8px]"
				src={GreenlineImage}
				alt="green_line"
				title="green_line"
			/>
		</span>
	);
}

function TopSection() {
	const { t } = useTranslation();

	return (
		<div className={cx('bg-primary-100 h-80', 'sm:h-400')}>
			<div className="custom-container h-full">
				<div className="flex items-center justify-center h-full">
					<div className="pt-24">
						<h1
							className={cx(
								'text-xl mb-2.5 font-red-hat font-normal text-[#3e4143] text-center',
								'sm:text-32 sm:font-bold'
							)}>
							<Trans i18nKey="the_simpcar_blog" components={{ simpcar: <SimpcarLogo /> }} />
						</h1>
						<div className="leading-8 text-center">{t('blog_page_description')}</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TopSection;
