import { useTranslation } from 'react-i18next';

import ArrowLeftIcon from 'src/assets/icons/arrow-left.svg';
import InfoIcon1 from 'src/assets/icons/antrieb.svg';
import InfoIcon2 from 'src/assets/icons/getriebe.svg';
import InfoIcon3 from 'src/assets/icons/treibstroff.svg';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { ICarItem } from 'src/types/car';
import { getImageUrl } from 'src/utils/string';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

interface CarSpecProps {
	term: number;
	km: number;
	data: ICarItem;
}

function CarSpec({ data, term, km }: CarSpecProps) {
	const { t, i18n } = useTranslation();
	const lng = i18n.language;
	const { getUrl } = useMultiLngRoutes();

	const constants = useAppConstants();
	const translations = useConstantsTranslation(lng);

	const carConstants = constants?.car;

	const { make, model, drive, transmission, fuel, prices, mainImages, id } = data;

	return (
		<section>
			<div className="custom-container">
				<div className="flex flex-col mb-30 md:flex-row">
					<div className="text-lg md:ml-auto md:hidden">
						<a href={getUrl('cars', { name: 'mini' })} className="flex items-center text-15 gap-5">
							<ArrowLeftIcon className=" text-primary" />
							{t('back_to_car')}
						</a>
					</div>
					<div className="w-full md:w-[450px] md:h-[340px] md:mr-15">
						<img className="w-full h-full" src={getImageUrl(mainImages[0])} />
					</div>
					<div className="flex-1">
						<div className="flex items-center mb-[20px] min-h-10">
							{lng === 'de' && (
								<div className="text-19 lg:text-22">
									Online <b>Auto-Abo</b> buchen
								</div>
							)}
							{lng === 'en' && (
								<div className="text-19 lg:text-22">
									Book <b>car subscription</b> online
								</div>
							)}
							{lng === 'fr' && (
								<div className="text-19 lg:text-22">
									Réservez un <b>abonnement voiture</b> en ligne
								</div>
							)}
							<div className="hidden text-lg ml-auto md:block">
								<a
									href={getUrl('car_detail', {
										id: id as string,
										tag: [make, ...model.split(' ')].join('-').toLowerCase()
									})}
									className="flex items-center gap-5">
									<ArrowLeftIcon className=" text-primary" />
									{t('back_to_car')}
								</a>
							</div>
						</div>
						<h4 className="text-22 lg:text-27 leading-[36px] text-primary mb-15 font-red-hat">
							{translations?.CAR_BRANDS[carConstants?.CAR_BRANDS[Number(make)] as string]} {model}{' '}
						</h4>
						<div className="flex items-center gap-25 w-full mb-10">
							<div className="flex gap-2 items-center">
								<span className="flex justify-center items-center size-10 bg-white rounded-5 text-center">
									<img className="m-auto size-4 lg:size-22" src={InfoIcon1} />
								</span>
								<span className="text-xs lg:text-sm text-[#8D8E93]">
									{translations?.CAR_DRIVE[carConstants?.CAR_DRIVE[Number(drive)] as string]}
								</span>
							</div>
							<div className="flex gap-2 items-center">
								<span className="flex justify-center items-center size-8 bg-white rounded-5 text-center">
									<img className="m-auto size-4 lg:size-22" src={InfoIcon2} />
								</span>
								<span className="text-xs lg:text-sm text-[#8D8E93]">
									{
										translations?.CAR_TRANSMISSION[
											carConstants?.CAR_TRANSMISSION[Number(transmission)] as string
										]
									}
								</span>
							</div>
							<div className="flex gap-2 items-center">
								<span className="flex justify-center items-center size-8 bg-white rounded-5 text-center">
									<img className="m-auto size-4 lg:size-22" src={InfoIcon3} />
								</span>
								<span className="text-xs lg:text-sm text-[#8D8E93]">
									{translations?.CAR_FUEL[carConstants?.CAR_FUEL[Number(fuel)] as string]}
								</span>
							</div>
						</div>
						<div className="flex flex-wrap gap-x-70 gap-y-2.5 text-sm lg:text-base">
							<div className="leading-[20px] lg:leading-[25px]">
								<b>{t('minimum_rental_period')}</b> <br /> {term} {t('months')}
							</div>
							<div className="leading-[20px] lg:leading-[25px]">
								<b>{t('mileage_package')}</b> <br />
								{km} Km {t('per_month')}
							</div>
							<div className="leading-[20px] lg:leading-[25px]">
								<b>{t('rental_price')}</b> <br /> CHF {prices[0]?.data[`${term}m${km}km`].value}.-{' '}
								{t('per_month')}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default CarSpec;
