import { useState, useEffect, useContext, createContext, ReactNode } from 'react';

import { endpoints, fetcher } from 'src/configs/axios';

import { Constants } from 'src/types/constants';
import useSWR from 'swr';

type ConstantsContextType = {
	constants: Constants | undefined;
};

const ConstantsContext = createContext<ConstantsContextType | undefined>(undefined);

export function ConstantsContextProvider({ children }: { children: ReactNode }) {
	const { data, error } = useSWR(endpoints.constants.get, fetcher);
	const [constants, setConstants] = useState<Constants>();

	useEffect(() => {
		if (data) {
			setConstants(data);
		}
	}, [data]);

	return (
		<ConstantsContext.Provider
			value={{
				constants
			}}>
			{children}
		</ConstantsContext.Provider>
	);
}

export function useConstants() {
	const context = useContext(ConstantsContext);
	if (context === undefined) {
		throw new Error('Context must be used within a Provider');
	}
	return context;
}
