import { useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { SelectItem } from 'src/types';
import SearchIcon from 'src/assets/icons/search.svg';
import CloseSvg from 'src/assets/icons/close-icon.svg';
import MultiSelect from 'src/components/multi-select';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

interface FilterItem {
	label: string;
	query: string;
	values: SelectItem[];
}

interface Props {
	selected: Record<string, SelectItem[]>;
	onChange: (val: Record<string, SelectItem[]>) => void;
}

function FiltersSection({ selected, onChange }: Props) {
	const { t, i18n } = useTranslation();
	const [detailMode, setDetailMode] = useState(false);

	const constants = useAppConstants();
	const translations = useConstantsTranslation(i18n.language);

	let filters: FilterItem[] = [
		{
			label: t('rental_period'),
			query: 'term',
			values: (constants?.car.SUB_DURATIONS ?? []).map((item) => ({
				value: item,
				label: `${item} ${item === 1 ? t('month') : t('months')}`
			})) as { value: number; label: string }[]
		},
		{
			label: t('brand'),
			query: 'make',
			values: Object.entries(constants?.car.CAR_BRANDS || {}).map(([key, value]) => ({
				label: translations?.CAR_BRANDS[value] as string,
				value: key
			}))
		},
		{
			label: t('type'),
			query: 'category',
			values: Object.entries(constants?.car.CATEGORY_OPTIONS || {}).map(([key, value]) => ({
				label: translations?.CATEGORY_OPTIONS[value] as string,
				value: key
			}))
		},
		{
			label: t('mechanism'),
			query: 'transmission',
			values: Object.entries(constants?.car.CAR_TRANSMISSION || {}).map(([key, value]) => ({
				label: translations?.CAR_TRANSMISSION[value] as string,
				value: key
			}))
		},
		{
			label: t('wheel'),
			query: 'drive',
			values: Object.entries(constants?.car.CAR_DRIVE || {}).map(([key, value]) => ({
				label: translations?.CAR_DRIVE[value] as string,
				value: key
			}))
		},
		{
			label: t('fuel'),
			query: 'fuel',
			values: Object.entries(constants?.car.CAR_FUEL || {}).map(([key, value]) => ({
				label: translations?.CAR_FUEL[value] as string,
				value: key
			}))
		}
	];

	if (detailMode) {
		const seats = constants?.car.CAR_SEATS;
		filters.push(
			...[
				{
					label: t('number_of_seats'),
					query: 'seats',
					values: Array.isArray(seats)
						? seats.map((item) => ({ label: `${item}`, value: item }))
						: []
				},
				{
					label: t('condition'),
					query: 'condition',
					values: [
						{ label: t('new_car'), value: 'new_car' },
						{ label: t('occassion'), value: t('occassion') }
					]
				}
			]
		);
	}

	return (
		<section>
			<div className="flex justify-center">
				<div
					className={cx(
						'bg-white rounded-lg mt-30 mb-18 text-left shadow-[0_6px_3px_-6px_#00000069] inline-block',
						'lg:rounded-100'
					)}>
					<div
						className={cx(
							'flex justify-center items-center flex-wrap gap-y-15 gap-x-[20px]',
							'lg:flex-nowrap py-4 px-4 lg:py-25 lg:px-30 '
						)}>
						{filters.map((filter, index) => (
							<div
								key={index + JSON.stringify(selected[filter.label] || [])}
								className={cx(
									'[&:last-child]:border-r-0 border-b border-b-[#cdcdcd] pb-2.5 pr-15 ',
									'lg:border-r lg:border-r-[#cdcdcd] lg:pb-0 lg:border-b-0'
								)}>
								<MultiSelect
									label={filter.label}
									data={filter.values}
									selected={selected[filter.query] || []}
									onChange={(val) => {
										onChange({ ...selected, [filter.query]: val });
									}}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="flex items-center justify-center gap-10">
				<button
					className="bg-none border-0 flex justify-center items-center gap-2.5"
					onClick={() => {
						setDetailMode(!detailMode);
					}}>
					<SearchIcon className="text-primary size-4" />
					<span className="text-[#16191E] text-13 lg:text-15">
						{detailMode ? t('standard_search') : t('detail_search')}
					</span>
				</button>
				<button
					className="bg-none border-0 flex justify-center items-center gap-2.5"
					onClick={() => {
						onChange({});
					}}>
					<CloseSvg className="text-primary size-3" />
					<span className="text-[#16191E] text-13 lg:text-15">{t('reset_filter')}</span>
				</button>
			</div>
		</section>
	);
}

export default FiltersSection;
