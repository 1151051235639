import { ReactNode, useState } from 'react';
import { Tab } from '@headlessui/react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { UseFormReset } from 'react-hook-form';

import Axios from 'src/configs/axios';
import ContactForm from 'src/components/form/contact-form-4';
import ScrollButtons from './scroll-buttons';
import { getObjectKey } from 'src/utils/object';
import { useAppConstants } from 'src/utils/constants';

const { Group: TabGroup, List: TabList, Panels: TabPanels, Panel: TabPanel } = Tab;

type RequestType = 1 | 2 | 3 | 4;

interface ItemProps {
	tabTitle: string;
	tabDescription: string;
	panelTitle: string;
	panelDescription: string;
	form: ReactNode;
	type: RequestType;
}

function ContactFormSection() {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();

	const constants = useAppConstants();

	const typeConstants = constants?.request.REQUEST_TYPES as { [key: string]: string } | undefined;
	const requestTypeValue = getObjectKey('general', typeConstants) || 1;

	const [type, setType] = useState<RequestType>(Number(requestTypeValue) as RequestType);

	const handleSave = async (
		data: { [x: string]: string | undefined },
		reset: UseFormReset<{
			[x: string]: string | undefined;
		}>
	) => {
		try {
			await Axios.post('/contacts/create', { ...data, type });
			reset();
			enqueueSnackbar('Sent your request successfully!');
		} catch (error) {
			console.log(error);
			enqueueSnackbar('Failed to send request!', { variant: 'error' });
		}
	};

	const items: ItemProps[] = [
		{
			tabTitle: t('message'),
			tabDescription: t('general_concerns'),
			panelTitle: t('submit_concerns_online'),
			panelDescription: t('submit_concerns_online_desc'),
			form: <ContactForm type="Form1" onSend={handleSave} />,
			type: Number(getObjectKey('general', typeConstants)) as RequestType
		},
		{
			tabTitle: t('recall'),
			tabDescription: t('personal_conversation'),
			panelTitle: t('we_will_call_you'),
			panelDescription: t('we_will_call_you_desc'),
			form: <ContactForm type="Form2" onSend={handleSave} />,
			type: Number(getObjectKey('recall', typeConstants)) as RequestType
		},
		{
			tabTitle: t('return'),
			tabDescription: t('cancel_subscription'),
			panelTitle: t('cancel_subscription_return_car'),
			panelDescription: t('cancel_subscription_return_car_desc'),
			form: <ContactForm type="Form3" onSend={handleSave} />,
			type: Number(getObjectKey('cancel', typeConstants)) as RequestType
		},
		{
			tabTitle: t('car_change'),
			tabDescription: t('mutate_subscription'),
			panelTitle: t('rent_new_car'),
			panelDescription: t('rent_new_car_desc'),
			form: <ContactForm type="Form4" onSend={handleSave} />,
			type: Number(getObjectKey('change', typeConstants)) as RequestType
		}
	];

	return (
		<section>
			<div className="custom-container">
				<div className="lg:max-w-[65%] mx-auto">
					<div className="-mt-55">
						<TabGroup>
							<div className="relative">
								<TabList
									id="tab-list"
									className={cx(
										'relative flex items-center rounded-15 p-15 bg-white shadow-[0_6px_3px_-6px_#00000069] overflow-auto scroll-smooth',
										'md:p-30 md:rounded-50 md:gap-25'
									)}>
									{items.map((item) => (
										<Tab
											key={item.tabTitle}
											className={cx(
												'group flex-1 text-left [&:nth-child(4n)]:border-none border-r border-[#909090] outline-none px-15',
												'md:px-0'
											)}
											onClick={() => setType(item.type)}>
											<div
												className={cx(
													'text-15 text-[#909090] duration-300 group-hover:text-primary group-aria-selected:text-primary',
													'md:text-lg md:mb-5'
												)}>
												{item.tabTitle}
											</div>
											<div
												className={cx(
													'whitespace-nowrap text-10 text-[#313131] font-medium',
													'md:text-13 md:whitespace-normal'
												)}>
												{item.tabDescription}
											</div>
										</Tab>
									))}
								</TabList>
								<ScrollButtons id="tab-list" />
							</div>
							<TabPanels>
								{items.map((item) => (
									<TabPanel key={item.panelTitle} className="py-50">
										<div className="text-center">
											<h3
												className={cx(
													'mb-15 text-22 text-[#3e4143] font-red-hat font-normal',
													'md:text-28 md:mb-25'
												)}>
												{item.panelTitle}
											</h3>
											<p className={cx('container-80 text-14 text-[#212529]', 'md:text-15')}>
												{item.panelDescription}
											</p>
											<div className={cx('bg-primary p-25 rounded-15 mt-25', 'md:mt-50 md:p-50')}>
												{item.form}
											</div>
										</div>
									</TabPanel>
								))}
							</TabPanels>
						</TabGroup>
					</div>
				</div>
			</div>
		</section>
	);
}

export default ContactFormSection;
