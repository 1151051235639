import { useConstants } from 'src/constants/constantsContext';

export function useAppConstants() {
	const { constants } = useConstants();

	return constants?.data.constants;
}

export function useConstantsTranslation(language: string = 'de') {
	const { constants } = useConstants();

	return constants?.data.translations[language].constants;
}
