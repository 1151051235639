import { SvgIconProps } from 'src/types';

export default function PhoneIcon({ className }: SvgIconProps) {
	return (
		<svg
			width="800px"
			height="800px"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			id="phone-out"
			className={className}>
			<path
				d="M21,15v3.93a2,2,0,0,1-2.29,2A18,18,0,0,1,3.14,5.29,2,2,0,0,1,5.13,3H9a1,1,0,0,1,1,.89,10.74,10.74,0,0,0,1,3.78,1,1,0,0,1-.42,1.26l-.86.49a1,1,0,0,0-.33,1.46,14.08,14.08,0,0,0,3.69,3.69,1,1,0,0,0,1.46-.33l.49-.86A1,1,0,0,1,16.33,13a10.74,10.74,0,0,0,3.78,1A1,1,0,0,1,21,15Z"
				fill="currentColor"></path>
			<path
				d="M21,10a1,1,0,0,1-1-1,5,5,0,0,0-5-5,1,1,0,0,1,0-2,7,7,0,0,1,7,7A1,1,0,0,1,21,10Z"
				fill="currentColor"></path>
			<path
				d="M17,10a1,1,0,0,1-1-1,1,1,0,0,0-1-1,1,1,0,0,1,0-2,3,3,0,0,1,3,3A1,1,0,0,1,17,10Z"
				fill="currentColor"></path>
		</svg>
	);
}
