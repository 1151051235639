import React from 'react';
import ReactDOM from 'react-dom/client';
import Providers from './lib/providers';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import './configs/i18n';
import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConstantsContextProvider } from './constants/constantsContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<Providers>
			<ConstantsContextProvider>
				<HelmetProvider>
					<Helmet>
						<title>Das clevere Auto-Abo von【simpcar】Ein Preis, alles drin!</title>
						<meta
							name="description"
							content="Auto Abo – Die Zukunft hat begonnen. Alles rund ums Auto ist inklusive, ausser Tanken ✓ Flexible Laufzeiten ⭐ ab CHF 449.- / Monat"
						/>
						<meta name="keywords" content="Auto Abo, Auto-Abo, Auto im Abo, Abo Auto" />
					</Helmet>
					<App />
				</HelmetProvider>
			</ConstantsContextProvider>
		</Providers>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
