import { CarPrice } from 'src/types/car';

export const useGetCarPrice = (prices: CarPrice) => {
	let min = 99999999,
		km = -1,
		month = -1;

	if (prices) {
		Object.entries(prices.data).forEach(([key, value]) => {
			if (value.enabled && value.value > 0 && value.value < min) {
				min = value.value;
				const arr = key.split('m');
				km = parseInt(arr[1], 10);
				month = parseInt(arr[0], 10);
			}
		});
	}

	return {
		price: min,
		km,
		month
	};
};
