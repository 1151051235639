import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { phoneRegExp } from 'src/constants';
import Input from '../input';
import Textarea from '../text-area';

import Button from '../button';
import Select from '../select';
import PhoneNumberInput from '../phone-input';

import '../radio.css';
import { createCarRequest } from 'src/api/request';
import { useSnackbar } from 'notistack';
import { getObjectKey } from 'src/utils/object';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

const validationSchema = yup.object({
	rentalTerm: yup.number().required(),
	make: yup.string().required(),
	model: yup.string().required(),
	transmission: yup.string().required(),
	fuel: yup.string().required(),
	condition: yup.string().required(),
	gender: yup.string().required(),
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	address: yup.string().required(),
	postalCode: yup.string().required(),
	city: yup.string().required(),
	email: yup.string().required().email(),
	phoneNumber: yup.string().required().matches(phoneRegExp),
	message: yup.string(),
	companyName: yup.string(),
	companyIndustry: yup.string(),
	companyAddress: yup.string(),
	companyZip: yup.string(),
	companyCity: yup.string()
});

interface DreamCarFormProps {
	onClose?: () => void;
}

function DreamCarForm({ onClose }: DreamCarFormProps) {
	const { t, i18n } = useTranslation();

	const constants = useAppConstants();
	const translations = useConstantsTranslation(i18n.language);

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch
	} = useForm({ resolver: yupResolver(validationSchema), mode: 'onChange' });

	const { enqueueSnackbar } = useSnackbar();

	const genderConstants = constants?.customer.GENDER as unknown as { [key: string]: string };

	const { gender, phoneNumber } = watch();

	const onSubmit = async (data: any) => {
		try {
			await createCarRequest(data);
			enqueueSnackbar('Sent your car request successfully!');
			reset();
			onClose?.();
		} catch (err) {
			console.log(err);
			enqueueSnackbar('Failed to send your request!', { variant: 'error' });
		}
	};

	return (
		<form
			id="dream-car-form"
			className="flex flex-col gap-15"
			onSubmit={handleSubmit((data) => onSubmit(data))}>
			<h5 className="mb-5 font-red-hat text-[#3e4143] text-19 lg:text-22">
				{t('describe_dream_car')}
			</h5>
			<div className="w-full flex flex-col sm:flex-row gap-25">
				<Input
					label={t('rental_period')}
					isError={!!errors['rentalTerm']}
					{...register('rentalTerm', { required: true })}
				/>
				<Input
					label={t('make')}
					isError={!!errors['make']}
					{...register('make', { required: true })}
				/>
			</div>
			<div className="flex flex-col w-full sm:flex-row gap-25">
				<div className="flex-1">
					<Input
						label={t('model')}
						isError={!!errors['model']}
						{...register('model', { required: true })}
					/>
				</div>
				<div className="flex-1"></div>
			</div>
			<div className="flex flex-col w-full sm:flex-row gap-25">
				<Input
					label={t('transmission')}
					isError={!!errors['transmission']}
					{...register('transmission', { required: true })}
				/>
				<Input
					label={t('fuel')}
					isError={!!errors['fuel']}
					{...register('fuel', { required: true })}
				/>
			</div>
			<div className="w-full flex flex-col sm:flex-row gap-25 lg:mb-[20px]">
				<div className="flex-1">
					<Input
						label={t('condition')}
						isError={!!errors['condition']}
						{...register('condition', { required: true })}
					/>
				</div>
				<div className="flex-1"></div>
			</div>

			<h5 className="mb-5 font-red-hat text-[#3e4143] text-19 lg:text-22">
				{t('your_contact_details')}
			</h5>
			<div className="w-full flex gap-[20px] items-center">
				<div className="flex items-center gap-3">
					<input
						id="mr"
						type="radio"
						value={getObjectKey('mr', genderConstants)}
						{...register('gender')}
						className="border"
					/>
					<label htmlFor="mr" className="text-[#212529] text-sm lg:text-base">
						{t('mr')}
					</label>
				</div>
				<div className="flex items-center gap-3">
					<input
						id="mrs"
						type="radio"
						value={getObjectKey('mrs', genderConstants)}
						{...register('gender')}
						className="border"
					/>
					<label htmlFor="mrs" className="text-[#212529] text-sm lg:text-base">
						{t('mrs')}
					</label>
				</div>
				<div className="flex items-center gap-3">
					<input
						id="company"
						type="radio"
						value={getObjectKey('company', genderConstants)}
						{...register('gender')}
						className="border"
					/>
					<label htmlFor="company" className="text-[#212529] text-sm lg:text-base">
						{t('company')}
					</label>
				</div>
				{!!errors['gender'] && (
					<span className="font-normal text-[80%] text-[#dc3545]">{t('required_field')}</span>
				)}
			</div>
			{gender === getObjectKey('company', genderConstants) && (
				<>
					<div className="w-full flex flex-col sm:flex-row gap-25">
						<Input
							label={t('family_name')}
							isError={!!errors['companyName']}
							{...register('companyName', { required: true })}
						/>

						<Select
							label={t('industry')}
							data={Object.entries(constants?.company ?? {}).map(([key, value]) => {
								return {
									label: translations?.COMPANY_INDUSTRY[value] as string,
									value: key
								};
							})}
							isError={!!errors['companyIndustry']}
							{...register('companyIndustry', { required: true })}
						/>
					</div>
					<div className="w-full flex flex-col sm:flex-row gap-25">
						<div className="flex flex-1 flex-col sm:flex-row gap-25">
							<Input
								label={t('address')}
								isError={!!errors['companyAddress']}
								{...register('companyAddress', { required: true })}
							/>
						</div>
						<div className="flex flex-1 flex-col sm:flex-row gap-25">
							<Input
								label={t('postal_code')}
								isError={!!errors['companyZip']}
								wrapperClass="md:!w-1/3"
								{...register('companyZip', { required: true })}
							/>
							<Input
								label={t('city')}
								isError={!!errors['companyCity']}
								wrapperClass="md:!w-2/3"
								{...register('companyCity', { required: true })}
							/>
						</div>
					</div>
					<h4 className="text-[20px] md:text-22 leading-[26px] text-[#3D3F40] font-bold my-2">
						{t('details_of_owner')}
					</h4>
				</>
			)}
			<div className="w-full flex flex-col sm:flex-row gap-25">
				<Input
					label={t('firstname')}
					isError={!!errors['firstName']}
					{...register('firstName', { required: true })}
				/>
				<Input
					label={t('lastname')}
					isError={!!errors['lastName']}
					{...register('lastName', { required: true })}
				/>
			</div>
			<div className="flex flex-col w-full sm:flex-row gap-25">
				<div className="flex-1">
					<Input
						label={t('address')}
						isError={!!errors['address']}
						{...register('address', { required: true })}
					/>
				</div>
				<div className="flex flex-col flex-1 sm:flex-row gap-25">
					<Input
						label={t('postal_code')}
						isError={!!errors['postalCode']}
						wrapperClass="md:!w-1/3"
						{...register('postalCode', { required: true })}
					/>
					<Input
						label={t('city')}
						isError={!!errors['city']}
						wrapperClass="md:!w-2/3"
						{...register('city', { required: true })}
					/>
				</div>
			</div>
			<div className="flex flex-col w-full sm:flex-row gap-25">
				<Input
					label={t('email_address')}
					isError={!!errors['email']}
					{...register('email', { required: true })}
				/>
				<PhoneNumberInput
					value={phoneNumber}
					label={t('telephone_number')}
					isError={!!errors['phoneNumber']}
					{...register('phoneNumber', { required: true })}
					onPhoneNumberChange={(val) => setValue('phoneNumber', val)}
				/>
			</div>
			<Textarea
				isOptional
				label={t('your_message')}
				isError={!!errors['message']}
				{...register('message')}
			/>
			<div className="mt-[20px]">
				<Button className="!w-auto" type="submit" label="Formular einsenden" />
			</div>
		</form>
	);
}

export default DreamCarForm;
