import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';

import PhoneIcon from 'src/assets/icons/phone-icon.svg';

function CallToActionSection() {
	const { t } = useTranslation();

	const { getUrl } = useMultiLngRoutes();

	return (
		<section className="custom-container pt-10">
			<div className="border-2 p-2 rounded-lg border-[#414A4F3B]">
				<div className="custom-container rounded-lg flex flex-col md:flex-row items-center justify-between gap-5 bg-primary text-white text-22 py-35">
					<div className="text-17 pl-8 sm:text-22 font-bold">{t('do_you_want_to_know_more')}</div>
					<div className="flex w-full gap-[10px] md:gap-0 md:items-center justify-start md:justify-end flex-col md:flex-row">
						<div className="flex items-center mr-6 ml-8">
							<div className="border rounded-full p-3 pt-3.5 pr-3.5 mr-6 border-white flex items-center justify-center w-fit">
								<PhoneIcon className="w-8 h-8 " />
							</div>
							<div>
								<div className="text-17 font-bold mb-2">{t('telephone_number')}</div>
								<div className="text-15">+41 31 558 25 00</div>
							</div>
						</div>
						<a
							className={cx(
								'py-3 px-26 ml-8 w-[300px] text-center bg-white rounded-sm text-md font-medium duration-300 text-black border-2 border-white mr-4',
								'hover:bg-[#0A1426] hover:text-white hover:border-[#0A1426]',
								'md:ml-0 md:w-fit'
							)}
							href={getUrl('contact')}
							title="Kontaktiere uns">
							{t('contact_us')}
						</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export default CallToActionSection;
