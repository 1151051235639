import { useTranslation } from 'react-i18next';

import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import './car-featured.css';
import GasPumpIcon from 'src/assets/icons/gas-pump-icon.svg';
import TransimssionIcon from 'src/assets/icons/transmission-icon.svg';
import SpeedometerIcon from 'src/assets/icons/speedometer-icon.svg';
import { useGetCarPrice } from 'src/hooks/use-car';
import { getImageUrl } from 'src/utils/string';
import { useGetFeatured } from 'src/api/car';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

function CarFeatured() {
	const { t, i18n } = useTranslation();
	const { getUrl } = useMultiLngRoutes();

	const constants = useAppConstants();
	const translations = useConstantsTranslation(i18n.language);

	const { car } = useGetFeatured();

	if (!car) {
		return null;
	}

	const { mainImages, model, make, fuel, transmission, id, prices, category, powerHp } = car;

	const { price } = car ? useGetCarPrice(prices[0]) : { price: 0 };

	return (
		<section className="custom-container pt-10">
			<div className="flex flex-col items-center justify-center mb-16">
				<p className="border-b-2 border-dotted border-primary text-primary text-base uppercase tracking-widest font-bold">
					{t('a_recommendation')}
				</p>
				<h2 className="text-[#0D1637] text-5xl font-bold mt-4">{t('modern_on_the_move')}</h2>
			</div>
			<div className="flex w-full flex-col lg:flex-row">
				<div className="flex w-full lg:w-7/12 relative">
					<div className="car-discount">
						<div className="relative">
							<img src={getImageUrl(mainImages[0])} alt="Car-Discount" />
						</div>
					</div>
					<div className="size-36 text-center pt-4 lg:pt-0 bg-white lg:flex flex-col justify-center items-center rounded-full leading-none absolute right-16 -top-4 z-10">
						<span className="text-26 text-[#16191E] font-medium">{t('from')}</span>
						<p className="text-46 text-[#16191E] font-bold leading-[52px]">
							{price}
							<span className="text-primary">.-</span>
						</p>
						<span className="font-bold text-[#16191E]">{t('per_month')}</span>
					</div>
				</div>
				<div className="w-full lg:w-5/12 p-4">
					<div className="rounded bg-white p-12">
						<div className="flex items-center justify-between mb-2">
							<p className="text-primary border-b-[3px] border-gray-300 font-semibold tracking-widest">
								{
									translations?.CATEGORY_OPTIONS[
										constants?.car.CATEGORY_OPTIONS[Number(category)] as string
									]
								}
							</p>
							<div className="px-6 py-1 bg-primary text-white rounded">{t('featured')}</div>
						</div>
						<h2 className="text-[#26232A] text-2xl font-bold tracking-wide mb-6">
							{translations?.CAR_BRANDS[constants?.car.CAR_BRANDS[Number(make)] as string]} –{' '}
							{model}
						</h2>
						{/* <p className="text-[#86898E] text-base font-medium leading-7 mb-6">
							{description}
						</p> */}
						{/* <div className="flex items-baseline gap-3 mb-16">
							<span className="text-red-600 font-semibold text-2xl">219,097$</span>
							<span className="text-base font-medium line-through text-[#86898E]">222,149$</span>
						</div> */}
						<div className="flex justify-between items-center mb-8">
							<div className="flex items-center gap-1">
								<GasPumpIcon className="w-[19px] h-[19px] -mt-[7px] mr-2" />
								<div className="text-sm">
									<p className="text-gray-400">{t('fuel')}</p>
									<p>{translations?.CAR_FUEL[constants?.car.CAR_FUEL[Number(fuel)] as string]}</p>
								</div>
							</div>
							<div className="flex items-center gap-1">
								<SpeedometerIcon className="w-[19px] h-[19px] -mt-[7px] mr-2" />
								<div className="text-sm">
									<p className="text-gray-400">{t('power_in_hp')}</p>
									<p>{powerHp}</p>
								</div>
							</div>
							<div className="flex items-center gap-1">
								<TransimssionIcon className="w-[19px] h-[19px] -mt-[7px] mr-2" />
								<div className="text-sm">
									<p className="text-gray-400">{t('transmission')}</p>
									<p>
										{
											translations?.CAR_TRANSMISSION[
												constants?.car.CAR_TRANSMISSION[Number(transmission)] as string
											]
										}
									</p>
								</div>
							</div>
						</div>
						<hr />
						<div className="mt-10">
							<a
								href={getUrl('car_detail', {
									id: id as string,
									tag: [make, ...(model ? model.split(' ') : [])].join('-').toLowerCase()
								})}
								className="bg-primary text-white text-base font-bold py-[21px] px-[62px] leading-none rounded uppercase">
								{t('view_offer')}
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export default CarFeatured;
