import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import InfoIcon1 from 'src/assets/icons/antrieb.svg';
import InfoIcon2 from 'src/assets/icons/getriebe.svg';
import InfoIcon3 from 'src/assets/icons/treibstroff.svg';
import CheckIcon from 'src/assets/icons/circle-check-primary.svg';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { ICarItem } from 'src/types/car';
import { getImageUrl } from 'src/utils/string';
import { useGetCarPrice } from 'src/hooks/use-car';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

interface CarProps {
	data: ICarItem;
}

function Car({ data }: CarProps) {
	const { rightTags, leftTags, mainImages, model, make, fuel, drive, transmission, id, prices } =
		data;
	const { t, i18n } = useTranslation();
	const { getUrl } = useMultiLngRoutes();

	const { price } = useGetCarPrice(prices[0]);

	const constants = useAppConstants();
	const translations = useConstantsTranslation(i18n.language);

	return (
		<div
			className={cx(
				'group relative rounded-14 pt-30 px-[20px] pb-[20px] bg-white duration-300',
				'hover:bg-[#dde2e6]'
			)}>
			{!!rightTags && (
				<span className="rounded-25 py-2 px-15 text-xs absolute right-15 top-[20px] bg-primary text-white leading-none">
					{translations?.CAR_RIGHT_TAGS[constants?.car.CAR_RIGHT_TAGS[Number(rightTags)] as string]}
				</span>
			)}
			{!!leftTags && (
				<div className="flex items-center gap-1 mt-1 text-xs absolute left-15 top-[20px] leading-none">
					<img src={CheckIcon} width={24} />
					<span>
						{translations?.CAR_LEFT_TAGS[constants?.car.CAR_LEFT_TAGS[Number(leftTags)] as string]}
					</span>
				</div>
			)}
			<img
				className={cx(
					'object-cover max-h-[150px] w-auto my-2.5 mx-auto duration-300',
					'group-hover:scale-[1.2]'
				)}
				src={getImageUrl(mainImages[0])}
			/>
			<div
				className={cx(
					'flex justify-between bg-[#edf3e1] mt-0 -mx-[20px] mb-25 py-15 px-[20px] duration-300',
					'group-hover:bg-primary'
				)}>
				<div>
					<span className="text-13 group-hover:text-white">
						{translations?.CAR_BRANDS[constants?.car.CAR_BRANDS[Number(make)] as string]}
					</span>
					<p className="font-bold text-15 group-hover:text-white">{model}</p>
				</div>
				<div>
					<span className="text-19 text-[#8D8E93] font-bold group-hover:text-white">
						ab <span className="text-primary group-hover:text-white">{price}.-</span>
					</span>
					<p className="text-[#8D8E93] text-xs text-right group-hover:text-white">
						{t('per_month')}
					</p>
				</div>
			</div>
			<div className="-mx-2 mb-27">
				<div className="flex items-center justify-center gap-3">
					<div className="flex items-center gap-2">
						<span className="flex justify-center items-center mb-1 p-1 size-8 bg-white rounded-5 text-center border border-[#e9f2d9]">
							<img className="m-auto size-18" src={InfoIcon1} />
						</span>
						<span className="text-11 text-[#8D8E93]">
							{translations?.CAR_DRIVE[constants?.car.CAR_DRIVE[Number(drive)] as string]}
						</span>
					</div>
					<div className="flex items-center gap-2">
						<span className="flex justify-center items-center mb-1 p-1 size-8 bg-white rounded-5 text-center border border-[#e9f2d9]">
							<img className="m-auto size-18" src={InfoIcon2} />
						</span>
						<span className="text-11 text-[#8D8E93]">
							{
								translations?.CAR_TRANSMISSION[
									constants?.car.CAR_TRANSMISSION[Number(transmission)] as string
								]
							}
						</span>
					</div>
					<div className="flex items-center gap-2">
						<span className="flex justify-center items-center mb-1 p-1 size-8 bg-white rounded-5 text-center border border-[#e9f2d9]">
							<img className="m-auto size-18" src={InfoIcon3} />
						</span>
						<span className="text-11 text-[#8D8E93]">
							{translations?.CAR_FUEL[constants?.car.CAR_FUEL[Number(fuel)] as string]}
						</span>
					</div>
				</div>
			</div>
			<div className="flex justify-center">
				<a
					className={cx(
						'w-full bg-primary  border-[2px] border-primary rounded-full py-15 px-5 text-white text-sm uppercase font-medium text-center duration-500',
						'hover:bg-transparent hover:text-primary'
					)}
					href={getUrl('car_detail', {
						id: id as string,
						tag: [make, ...model.split(' ')].join('-').toLowerCase()
					})}>
					{t('view_offer')}
				</a>
			</div>
		</div>
	);
}

export default Car;
