import { useEffect, useState } from 'react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './reviews-section.css';

import Car from './car';
import { useSearchCars } from 'src/api/car';
import { getObjectKey } from 'src/utils/object';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

function CarsSection() {
	const { t, i18n } = useTranslation();

	const constants = useAppConstants();
	const translations = useConstantsTranslation(i18n.language);

	const carTypeConstants = constants?.car.CATEGORY_OPTIONS as { [key: number]: string };
	const carTypes = [
		getObjectKey('limousine', carTypeConstants),
		getObjectKey('small_car', carTypeConstants),
		getObjectKey('suv', carTypeConstants),
		getObjectKey('combi', carTypeConstants)
	];
	const [selected, setSelected] = useState(carTypes[0]);

	useEffect(() => {
		setSelected(carTypes[0]);
	}, [carTypeConstants]);

	const { searchResults: cars } = useSearchCars({ category: [selected] });

	return (
		<section className="py-70">
			<div className="custom-container">
				<div className="text-center">
					<h2 className="text-22 lg:text-33 text-[#3e4143] font-red-hat font-medium mb-2">
						<span className="text-primary">{t('subscribe_instead_of_lease')}</span> –{' '}
						{t('browse_vehicles_now')}
					</h2>
					<h3 className="my-2.5 text-base lg:text-lg text-[#0C111F] font-light font-red-hat">
						{t('start_browsing_subscription_vehicles')}
					</h3>
				</div>
				<div className="flex justify-center">
					<ul className="flex mt-10 mb-4 mx-auto gap-[20px] flex-wrap justify-center">
						{carTypes.map((item) => (
							<a
								key={item}
								className={cx(
									' rounded-10 p-2.5 lg:py-3 lg:px-35 text-base font-medium text-[#23262D] cursor-pointer bg-white',
									{
										'!bg-primary !text-white': item === selected
									}
								)}
								onClick={() => {
									setSelected(item);
								}}>
								{translations?.CATEGORY_OPTIONS[carTypeConstants[Number(item)]]}
							</a>
						))}
					</ul>
				</div>
				<div className="mt-30">
					<Swiper
						slidesPerView={1}
						spaceBetween={10}
						pagination={{
							clickable: true,
							bulletActiveClass: 'bullet-active'
						}}
						breakpoints={{
							640: {
								slidesPerView: 1,
								spaceBetween: 20
							},
							768: {
								slidesPerView: 2,
								spaceBetween: 40
							},
							1024: {
								slidesPerView: 3,
								spaceBetween: 40
							},
							1200: {
								slidesPerView: 4,
								spaceBetween: 40
							}
						}}
						modules={[Pagination]}
						className="reviews-swiper px-0">
						{cars.map((item) => (
							<SwiperSlide key={item.id}>
								<Car data={item} />
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
}

export default CarsSection;
