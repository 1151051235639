import TopSection from 'src/components/blogs/top-section';
import { PostList } from '../components/blogs/post-list';

export default function Blogs() {
	return (
		<>
			<TopSection />
			<main className="mx-auto mt-4 w-full max-w-3xl flex-col pt-36 pb-24 space-y-16 px-4 lg:px-0">
				<PostList />
			</main>
		</>
	);
}
export const revalidate = 60;
