import { useEffect, useMemo, useState } from 'react';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import GreenlineImage from 'src/assets/images/green_line_075.svg';
import CheckIcon from 'src/assets/icons/check-icon.svg';
import ContactImage from 'src/assets/images/simpcar_kontakt.png';
import CloseIcon from 'src/assets/icons/close-icon.svg';
import Slider from '../slider';
import Button from '../button';
import Drawer from '../drawer';
import RequestOfferForm from '../form/request-offer-form';
import { useMultiLngRoutes } from 'src/hooks/use-multi-routes';
import { createOrder } from 'src/api/order';
import { IOrderItem } from 'src/types/order';
import { ICarItem } from 'src/types/car';
import { formatNumber } from 'src/utils/number';

import './right-section.css';
import { useGetCarPrice } from 'src/hooks/use-car';
import { Constants } from 'src/types/constants';
import { useConstantsTranslation } from 'src/utils/constants';

interface Props {
	data: ICarItem;
	constants: { [key: string]: { [key: string]: any } };
}

function RightSection({ data, constants }: Props) {
	const { t, i18n } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const lng = i18n.language;
	const { prices, id, model, make } = data;

	const translations = useConstantsTranslation(lng);

	const { getUrl } = useMultiLngRoutes();
	const { km: initKm, month: initMonth } = useGetCarPrice(prices[0]);

	const [isOpen, setIsOpen] = useState(false);
	const [km, setKm] = useState(0);
	const [month, setMonth] = useState(0);
	const kms = constants?.car.SUB_KM;

	const items = [t('all_services_included'), t('no_hidden_costs'), t('fast_reliable_fair')];

	const months = useMemo(() => {
		const available: number[] = [];

		constants?.car.SUB_DURATIONS.forEach((mItem: number) => {
			if (kms && prices[0]?.data[`${mItem}m${kms[km]}km`]?.enabled === true) {
				available.push(mItem);
			}
		});

		return available;
	}, [prices, km]);

	useEffect(() => {
		if (!months.includes(months[month])) setMonth(0);
	}, [km, month, months]);

	useEffect(() => {
		setKm(kms.findIndex((item: number) => item === initKm));
		setMonth(months.findIndex((item) => item === initMonth));
	}, [initKm, initMonth]);

	const handleChange = async (data: Record<string, string>) => {
		try {
			await createOrder({ carId: id, ...data } as unknown as IOrderItem);
			setIsOpen(false);
			enqueueSnackbar('Sent your order successfully!');
		} catch (err) {
			console.log(err);
			enqueueSnackbar('Failed to send your order!', { variant: 'error' });
		}
	};

	return (
		<div className="mt-0 lg:-mt-100">
			<div
				id="rates_calculator"
				className={cx('bg-[#ECF3DF] p-18 border border-primary rounded-[20px] mb-30', 'md:p-30')}>
				{lng === 'de' && (
					<h2 className="text-22 lg:text-27 font-red-hat text-[#3e4143] font-medium mb-2">
						<span>Abo</span> konfigurieren und{' '}
						<span className="relative">
							Miete
							<img
								className="absolute w-full left-0 -bottom-2"
								src={GreenlineImage}
								alt="green_line"
								title="green_line"
							/>
						</span>{' '}
						berechnen
					</h2>
				)}
				{lng === 'en' && (
					<h2 className="text-22 lg:text-27 font-red-hat text-[#3e4143] font-medium mb-2">
						<span>Configure</span> subscription and{' '}
						<span className="relative">
							Calculate
							<img
								className="absolute w-full left-0 -bottom-2"
								src={GreenlineImage}
								alt="green_line"
								title="green_line"
							/>
						</span>{' '}
						rent
					</h2>
				)}
				{lng === 'fr' && (
					<h2 className="text-22 lg:text-27 font-red-hat text-[#3e4143] font-medium mb-2">
						<span>Configurer</span> l'abonnement et{' '}
						<span className="relative">
							calculer
							<img
								className="absolute w-full left-0 -bottom-2"
								src={GreenlineImage}
								alt="green_line"
								title="green_line"
							/>
						</span>{' '}
						le loyer
					</h2>
				)}
				<div>
					<h4 className="text-base mt-4 lg:mt-0 lg:text-[20px] font-red-hat font-normal text-[#3e4143] mb-2">
						{t('monthly_mileage_package')}
					</h4>
					<Slider
						id="monthly"
						wrapperClassName="mt-2.5 mb-30"
						steps={kms}
						defaultValue={km}
						onChange={(val) => setKm(val)}
					/>
				</div>
				<div>
					<h4 className="text-base mt-4 lg:mt-0 lg:text-[20px] font-red-hat font-normal text-[#3e4143] mb-2">
						{t('rental_period_month')}
					</h4>
					<Slider
						id="rent"
						wrapperClassName="mt-2.5 mb-30"
						steps={months}
						defaultValue={month}
						onChange={(val) => setMonth(val)}
					/>
				</div>
				<div className="flex justify-between items-center mb-[20px]">
					<h5
						className={cx(
							'text-base lg:text-lg font-bold leading-[30px] font-red-hat text-[#3e4143]',
							'md:text-[20px]'
						)}>
						{t('your_monthly_rate')} <span className="text-13 font-medium">in CHF</span>
					</h5>
					<p className={cx('font-bold text-xl leading-[35px] text-[#3e4143]', 'md:text-2xl')}>
						{formatNumber(prices[0]?.data[`${months[month]}m${kms[km]}km`]?.value || 0)}
					</p>
				</div>
				<div className="flex flex-wrap items-center mb-30">
					{items.map((item, index) => (
						<div key={index} className="w-full text-white flex items-center gap-15 mb-5">
							<span
								className={cx(
									'size-[20px] bg-primary rounded-full p-1 text-white flex items-center justify-center',
									'md:size-26'
								)}>
								<CheckIcon />
							</span>
							<span className="text-15 text-[#16191E]">{item}</span>
						</div>
					))}
				</div>
				<div className={cx('flex gap-2.5', 'md:gap-30 md:mb-30')}>
					<div className="flex-1">
						<Button
							label={t('book_online')}
							className={cx('w-full !text-sm !px-2.5 !py-2', 'md:!text-lg')}
							href={getUrl('auto_subscribe', {
								id: id as string,
								km: kms[km],
								term: months[month]
							})}></Button>
					</div>
					<div className="flex-1">
						<Button
							label={t('request_quote')}
							className={cx(
								'w-full bg-white !text-primary hover:bg-primary hover:!text-white !text-sm  !px-2.5 !py-2',
								'md:!text-lg'
							)}
							onClick={() => {
								setIsOpen(true);
							}}></Button>
					</div>
				</div>
			</div>

			<div className={cx('flex lg:items-center gap-2.5 mb-25', 'md:gap-30')}>
				<div
					className={cx(
						'size-50 rounded-full overflow-hidden bg-[#cdcdcd] min-w-50',
						'md:size-100 md:min-w-100'
					)}>
					<img src={ContactImage} />
				</div>
				<div>
					{lng === 'de' && (
						<h6
							className={cx(
								'font-medium text-15 text-[#3e4143] mb-2.5 leading-[22px] font-red-hat',
								'md:text-[20px]'
							)}>
							Du hast <span className="text-primary">Fragen</span> und brauchst{' '}
							<span className="text-primary">Beratung</span>?
						</h6>
					)}

					{lng === 'en' && (
						<h6
							className={cx(
								'font-medium text-15 text-[#3e4143] mb-2.5 leading-[22px] font-red-hat',
								'md:text-[20px]'
							)}>
							Do you have <span className="text-primary">questions</span> and need{' '}
							<span className="text-primary">advice</span>?
						</h6>
					)}

					{lng === 'fr' && (
						<h6
							className={cx(
								'font-medium text-15 text-[#3e4143] mb-2.5 leading-[22px] font-red-hat',
								'md:text-[20px]'
							)}>
							Vous avez des <span className="text-primary">questions</span> et avez besoin{' '}
							<span className="text-primary">Conseils</span>?
						</h6>
					)}
					<p className={cx('font-medium text-[#3e4143] leading-[22px] text-14', 'md:text-base')}>
						{' '}
						{t('customer_service_hearing_from_you')}. →{' '}
						<a href={getUrl('contact')} title="Kontaktseite">
							<b>{t('to_contact_page')}</b>
						</a>
					</p>
				</div>
			</div>
			<Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
				<>
					<div className="bg-primary p-18 lg:p-30">
						<div className="w-[calc(100%-60px)] text-white">
							<div className="text-lg pr-10 lg:pr-0 lg:text-xl font-bold mb-15">
								{t('request_quote_and_data_sheet', {
									car: `${translations?.CAR_BRANDS[constants?.car.CAR_BRANDS[Number(make)]]} ${model}`
								})}
							</div>
							<div className="text-sm lg:text-base">{t('we_happy_send_offer_sentence')}</div>
						</div>
						<span
							className="absolute right-4 lg:right-35 top-4 lg:top-35 size-35 bg-white rounded-full flex justify-center items-center cursor-pointer text-primary"
							onClick={() => {
								setIsOpen(false);
							}}>
							<CloseIcon className="w-15" />
						</span>
					</div>
					<div className="p-18 pt-0 lg:p-30">
						<RequestOfferForm onChange={handleChange} />

						<div className="mt-[20px] flex gap-15 items-center">
							<Button
								className="!w-auto"
								type="submit"
								label="Formular einsenden"
								form="request-offer-form"
							/>
							<a
								className="font-medium text-[#909090] cursor-pointer hover:opacity-75"
								onClick={() => {
									setIsOpen(false);
								}}>
								{t('cancel')}
							</a>
						</div>
					</div>
				</>
			</Drawer>
		</div>
	);
}

export default RightSection;
