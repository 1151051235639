import { SvgIconProps } from 'src/types';

export default function SpeedometerIcon({ className }: SvgIconProps) {
	return (
		<svg
			height="800px"
			width="800px"
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 19.804 19.804"
			className={className}>
			<g>
				<path
					d="M9.902,0.001C4.433,0.001,0,4.433,0,9.903c0,5.469,4.433,9.9,9.902,9.9
		c5.471,0,9.902-4.433,9.902-9.9C19.804,4.434,15.373,0.001,9.902,0.001z M9.902,18.724c-4.87,0-8.819-3.947-8.819-8.819
		c0-4.873,3.949-8.819,8.819-8.819c4.872,0,8.82,3.947,8.82,8.819C18.72,14.774,14.773,18.724,9.902,18.724z M6.936,14.186h1.612
		v2.672H6.936V14.186z M9.38,14.186h1.612v2.672H9.38C9.38,16.858,9.38,14.186,9.38,14.186z M11.782,14.186h1.612v2.672h-1.612
		V14.186z M11.218,10.506c0,0.455-0.371,0.826-0.825,0.826c-0.438,0-0.798-0.342-0.824-0.774L6.658,6.934l3.736,2.748
		C10.847,9.682,11.218,10.051,11.218,10.506z M5.21,11.88l0.715,0.716l-1.342,1.343l-0.716-0.717L5.21,11.88z M4.587,9.382H2.689
		V8.37h1.898C4.587,8.37,4.587,9.382,4.587,9.382z M4.995,3.869l1.342,1.343L5.621,5.927L4.279,4.584L4.995,3.869z M10.408,4.332
		H9.396V2.434h1.012V4.332z M14.523,6.31l-0.717-0.715l1.344-1.343l0.715,0.716L14.523,6.31z M17.407,8.37v1.012H15.51V8.37H17.407z
		 M14.869,12.083l1.344,1.342l-0.715,0.715l-1.344-1.342L14.869,12.083z"
				/>
			</g>
		</svg>
	);
}
