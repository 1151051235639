import { IBlogItem } from 'src/types/blog';
import { stringToFriendlyDate } from '../../utils/date';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

export default function AuthorAttribution({ post }: { post: IBlogItem }): JSX.Element {
	const { t } = useTranslation();
	return (
		<div className="flex space-x-1">
			<span>{t('by')}</span>
			<a
				// href={`/author/${post.metadata.author?.slug}`}
				className="font-medium text-green-600 ">
				{post.user.firstName} {post.user.lastName}
			</a>
			<span>
				{t('on')} {format(post.publishedAt || post.createdAt, 'dd.MM.yyyy')}
			</span>
		</div>
	);
}
