import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { phoneRegExp, birthdayRegExp } from 'src/constants';
import Input from '../input';
import Select from '../select';
import { Gender } from 'src/types/order';
import PhoneNumberInput from '../phone-input';

import '../radio.css';
import { getObjectKey } from 'src/utils/object';
import { useAppConstants, useConstantsTranslation } from 'src/utils/constants';

export interface StepTwoData {
	gender: Gender;
	firstName: string;
	lastName: string;
	street: string;
	no: string;
	postalCode: string;
	city: string;
	nationality: string;
	residencePermit?: string;
	birthday: string;
	employmentRelationship: string;
	email: string;
	phoneNumber: string;
	companyName?: string;
	companyIndustry?: string;
	companyAddress?: string;
	companyZip?: string;
	companyCity?: string;
}

export interface StepTwoProps {
	submitted?: boolean;
	formData?: StepTwoData;
	onChange?: (val: StepTwoData) => void;
}

const validationSchema = yup.object({
	gender: yup.mixed<Gender>(),
	firstName: yup.string().required(),
	lastName: yup.string().required(),
	street: yup.string().required(),
	no: yup.string().required(),
	postalCode: yup.string().required(),
	city: yup.string().required(),
	nationality: yup.string().required(),
	residencePermit: yup.string(),
	birthday: yup.string().required().matches(birthdayRegExp, { message: 'dd.mm.yyyy' }),
	employmentRelationship: yup.string().required(),
	email: yup.string().required().email(),
	phoneNumber: yup.string().required().matches(phoneRegExp),
	companyName: yup.string(),
	companyIndustry: yup.string(),
	companyAddress: yup.string(),
	companyZip: yup.string(),
	companyCity: yup.string()
});

function RequestOfferFormTwo({ formData, onChange }: StepTwoProps) {
	const { t, i18n } = useTranslation();

	const constants = useAppConstants();
	const translations = useConstantsTranslation(i18n.language);

	const {
		setValue,
		register,
		handleSubmit,
		formState: { errors },
		watch
	} = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onChange',
		defaultValues: formData
	});

	const { gender, nationality, phoneNumber } = watch();
	const isSwiss = nationality === getObjectKey('CHE', constants?.countries) || !nationality;
	const genderConstants = constants?.customer.GENDER as unknown as { [key: string]: string };

	return (
		<form
			id="request-offer-form-2"
			className="flex flex-col gap-15"
			onSubmit={handleSubmit(({ gender, ...rest }) => {
				onChange?.({ gender: gender as Gender, ...rest });
			})}>
			<div className="w-full flex gap-[20px] items-center flex-wrap">
				<div className="flex items-center gap-3">
					<input
						id="mr"
						type="radio"
						value={getObjectKey('mr', genderConstants)}
						{...register('gender')}
						className="border"
					/>
					<label htmlFor="mr" className="text-[#212529]">
						{t('mr')}
					</label>
				</div>
				<div className="flex items-center gap-3">
					<input
						id="mrs"
						type="radio"
						value={getObjectKey('mrs', genderConstants)}
						{...register('gender')}
						className="border"
					/>
					<label htmlFor="mrs" className="text-[#212529]">
						{t('mrs')}
					</label>
				</div>
				<div className="flex items-center gap-3">
					<input
						id="company"
						type="radio"
						value={getObjectKey('company', genderConstants)}
						{...register('gender')}
						className="border"
					/>
					<label htmlFor="company" className="text-[#212529]">
						{t('company')}
					</label>
				</div>
				{!!errors['gender'] && (
					<span className="font-normal text-[80%] text-[#dc3545]">{t('required_field')}</span>
				)}
			</div>

			{Number(gender) === Number(getObjectKey('company', genderConstants)) && (
				<>
					<div className="w-full flex flex-col sm:flex-row gap-25">
						<Input
							label={t('family_name')}
							isError={!!errors['companyName']}
							{...register('companyName', { required: true })}
						/>

						<Select
							label={t('industry')}
							data={Object.entries(constants?.company ?? {}).map(([key, value]) => {
								return {
									label: translations?.COMPANY_INDUSTRY[value] as string,
									value: key
								};
							})}
							isError={!!errors['companyIndustry']}
							{...register('companyIndustry', { required: true })}
						/>
					</div>
					<div className="w-full flex flex-col sm:flex-row gap-25">
						<div className="flex flex-1 flex-col sm:flex-row gap-25">
							<Input
								label={t('address')}
								isError={!!errors['companyAddress']}
								{...register('companyAddress', { required: true })}
							/>
						</div>
						<div className="flex flex-1 flex-col sm:flex-row gap-25">
							<Input
								label={t('postal_code')}
								isError={!!errors['companyZip']}
								wrapperClass="md:!w-1/3"
								{...register('companyZip', { required: true })}
							/>
							<Input
								label={t('city')}
								isError={!!errors['companyCity']}
								wrapperClass="md:!w-2/3"
								{...register('companyCity', { required: true })}
							/>
						</div>
					</div>
					<h4 className="text-[20px] md:text-22 leading-[26px] text-[#3D3F40] font-bold my-2">
						{t('details_of_owner')}
					</h4>
				</>
			)}
			<div className="w-full flex flex-col sm:flex-row gap-25">
				<Input
					label={t('firstname')}
					isError={!!errors['firstName']}
					{...register('firstName', { required: true })}
				/>
				<Input
					label={t('lastname')}
					isError={!!errors['lastName']}
					{...register('lastName', { required: true })}
				/>
			</div>
			<div className="w-full flex flex-col sm:flex-row gap-25">
				<div className="flex flex-1 flex-col sm:flex-row gap-25">
					<Input
						wrapperClass="md:!w-3/4"
						label={t('street')}
						isError={!!errors['street']}
						{...register('street', { required: true })}
					/>

					<Input
						wrapperClass="md:!w-1/4"
						label={t('number')}
						isError={!!errors['no']}
						{...register('no', { required: true })}
					/>
				</div>
				<div className="flex flex-1 flex-col sm:flex-row gap-25">
					<Input
						wrapperClass="md:!w-1/3"
						label={t('postal_code')}
						isError={!!errors['postalCode']}
						{...register('postalCode', { required: true })}
					/>
					<Input
						wrapperClass="md:!w-2/3"
						label={t('city')}
						isError={!!errors['city']}
						{...register('city', { required: true })}
					/>
				</div>
			</div>

			<div className="w-full flex flex-col sm:flex-row gap-25">
				<div className="flex-1">
					<Select
						label={t('nationality')}
						isError={!!errors['nationality']}
						data={Object.entries(constants?.countries ?? {}).map(([key, value]) => {
							return {
								label: translations?.countries[value] as string,
								value: key
							};
						})}
						{...register('nationality', { required: true })}
					/>
				</div>

				{!isSwiss && (
					<div className="flex-1">
						<Select
							label={t('residence_permit')}
							data={Object.entries(constants?.customer.RESIDENCES ?? {}).map(([key, value]) => {
								return {
									label: translations?.RESIDENCES[value] as string,
									value: key
								};
							})}
							isError={!!errors['residencePermit']}
							{...register('residencePermit', { required: true })}
						/>
					</div>
				)}
				{isSwiss && (
					<div className="flex-1">
						<Input
							placeholder={t('date_placeholder')}
							label={t('birthday')}
							isError={!!errors['birthday']}
							{...register('birthday', { required: true })}
						/>
					</div>
				)}
			</div>
			<div className="w-full flex flex-col sm:flex-row gap-25">
				{!isSwiss && (
					<div className="flex-1">
						<Input
							placeholder={t('date_placeholder')}
							label={t('birthday')}
							isError={!!errors['birthday']}
							{...register('birthday', { required: true })}
						/>
					</div>
				)}
				<div className="flex-1">
					<Select
						label={t('employmentRelationship')}
						data={Object.entries(constants?.customer.EMPLOYMENT ?? {}).map(([key, value]) => {
							return {
								label: translations?.EMPLOYMENT[value] as string,
								value: key
							};
						})}
						isError={!!errors['employmentRelationship']}
						{...register('employmentRelationship', { required: true })}
					/>
				</div>
				{isSwiss && (
					<div className="flex-1">
						<PhoneNumberInput
							value={phoneNumber}
							label={t('telephone_number')}
							isError={!!errors['phoneNumber']}
							{...register('phoneNumber', { required: true })}
							onPhoneNumberChange={(val) => setValue('phoneNumber', val)}
						/>
					</div>
				)}
			</div>

			<div className="w-full flex flex-col sm:flex-row gap-25">
				{!isSwiss && (
					<div className="flex-1">
						<PhoneNumberInput
							value={phoneNumber}
							label={t('telephone_number')}
							isError={!!errors['phoneNumber']}
							{...register('phoneNumber', { required: true })}
							onPhoneNumberChange={(val) => setValue('phoneNumber', val)}
						/>
					</div>
				)}
				<div className="flex-1">
					<Input
						label={t('email_address')}
						isError={!!errors['email']}
						{...register('email', { required: true })}
					/>
				</div>
				{isSwiss && <div className="flex-1"></div>}
			</div>
		</form>
	);
}

export default RequestOfferFormTwo;
