import HeroSection from 'src/components/home/hero-section';
import GoogleReviewsSection from 'src/components/home/google-reviews-section';
import ServiceSection from 'src/components/home/services-section';
import SubscriptionSection from 'src/components/home/subscription-section';
import CarsSection from 'src/components/home/cars-section';
import ReviewsSection from 'src/components/home/reviews-section';
import WhatWeDoSection from 'src/components/home/what-we-do-section';
// import MobileAppSection from 'src/components/home/mobile-app-section';
import QuestionsSection from 'src/components/home/questions-section';
import CarFeatured from 'src/components/home/car-featured';

function Home() {
	return (
		<section>
			<HeroSection />
			<GoogleReviewsSection />
			<div className="pt-6 bg-white lg:pt-16">
				<div className="custom-container">
					<ServiceSection />
					<SubscriptionSection />
				</div>
			</div>
			<div className="custom-container">
				<CarsSection />
			</div>
			<ReviewsSection />
			<WhatWeDoSection />
			{/* <MobileAppSection /> */}
			<CarFeatured />
		</section>
	);
}

export default Home;
