import { useParams } from 'react-router-dom';
import { FaCalendarCheck } from 'react-icons/fa';
import cx from 'classnames';

import InfoSection from 'src/components/car-detail/info-section';
import LeftSection from 'src/components/car-detail/left-section';
import RightSection from 'src/components/car-detail/right-section';
import { useGetCar } from 'src/api/car';
import { useAppConstants } from 'src/utils/constants';

function CarDetail() {
	const { id } = useParams();
	const { car, carLoading } = useGetCar(id as string);

	const constants = useAppConstants();

	if (carLoading || !constants) {
		return null;
	}

	return (
		<div className="pt-100 lg:pt-150 bg-[#F4F5F8]">
			<InfoSection data={car} />
			<div className="bg-white">
				<div className="custom-container">
					<div className="flex flex-col lg:flex-row justify-center gap-30">
						<div className="w-full lg:w-1/2">
							<LeftSection car={car} />
						</div>
						<div className="w-full lg:w-1/2">
							<RightSection data={car} constants={constants} />
						</div>
					</div>
				</div>
			</div>
			{/* <a
				className={cx(
					'fixed bottom-[20px] right-30 flex flex-col justify-center items-center p-2.5 size-130 text-15 text-white z-50 bg-primary rounded-full cursor-pointer',
					'hover:bg-black'
				)}
				onClick={() => {
					document.getElementById('rates_calculator')?.scrollIntoView({ behavior: 'smooth' });
				}}>
				<FaCalendarCheck className="text-28 mb-2.5" />
				<div className="text-center">
					Online Preis
					<br />
					berechnen
				</div>
			</a> */}
		</div>
	);
}

export default CarDetail;
