import { SinglePost } from '../components/blog-detail/single-post';
import { useParams } from 'react-router-dom';

export default function BlogDetail() {
	const { url } = useParams();
	return (
		<main className="pt-36 pb-24">
			<SinglePost url={url as string} />
		</main>
	);
}
